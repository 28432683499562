<template>
    <div class="banner-area">
        <div class="container m-head">
            <div class="row fl-j-c">
                <div class="col-lg-6 text-center">
                    <p>
                        18 years of supporting refugee and asylum seeker doctors, nurses and allied health professionals
                        into NHS employment.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InnerBanner'
};
</script>

<style scoped lang="scss">
img {
    width: 100%;
    height: auto;
}

.banner-area {
    font-family: 'poppins-semibold', serif;
    font-size: 1.5rem;
    background: url('../../assets/img/sub-banner.png');
    background-position: left;
    background-size: cover;
    min-height: 70vh;
    text-align: left;
    display: flex;
    align-items: center;
    color: #0462c6;

    .m-head {
        margin-top: 87px;
    }

    @media (max-width: 992px) {
        .m-head {
            margin-top: 103px;

        }
    }
}

@media (max-width: 992px) {
    .banner-area {
        font-size: 1.562rem;
        text-align: center;
        //background-position: 80%;
        padding: 0;
        background-repeat: no-repeat;

    }

    .banner-area p {
        width: 100%;
    }

}

@media (min-width: 1024px) and (max-width: 1399px) {
    .banner-area {
        background-position: 80%;
    }
}
</style>
