<template>
    <div class="banner-area">
        <div class="container m-head">
            <div class="row">
                <div class="col-lg-7">
                    <p>
                        18 years of supporting refugee and asylum seeker doctors, nurses and allied health professionals
                        into NHS employment.
                    </p>
                    <div class="row mt-5">
                        <div class="col">
                            <img src="../../assets/img/footer-logo-1.png" class="img-fluid banner-client1" alt="#">

                            <img src="../../assets/img/footer-logo-2.png" class="img-fluid banner-client2" alt="#">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Banner'
};
</script>

<style scoped lang="scss">
img {
    width: 100%;
    height: auto;
}

.banner-client1 {
    width: 180px;
    margin-right: 20px;
    background-color: rgba(0, 0, 0, 0.32);
    padding: 15px;
}
.banner-client2 {
    width: 256px;
    margin-right: 20px;
    background-color: rgba(0, 0, 0, 0.32);
    padding: 15px;
}

.banner-area {
    font-family: 'poppins-semibold', serif;
    font-size: 1.8rem;
    background: url('../../assets/img/banner.png');
    background-position: left;
    background-size: cover;
    min-height: 100vh;
    text-align: left;
    display: flex;
    align-items: center;

    .m-head {
        margin-top: 87px;
    }

    @media (max-width: 992px) {
        .m-head {
            margin-top: 103px;

        }
    }

}

@media (max-width: 992px) {
    .banner-area {
        font-size: 1.562rem;
        text-align: center;
        background-position: 80%;
        padding: 0;
        background-repeat: no-repeat;

    }

    .banner-area p {
        width: 100%;
    }

}

@media (min-width: 1024px) and (max-width: 1399px) {
    .banner-area {
        background-position: 80%;
    }
}
</style>
