<template>
    <div class="bg-2 " id="top">
        <div class="nav-container nav-bg pb-lg-0 p-3 p-lg-0">
            <lego-nav-bar drop-down-animation="fade" drop-down-animation-mobile="fade" class="container py-4" shadow="0"
                          :items="menu">
                <template #logo>
                    <router-link to="/" class="d-flex">
                        <img src="../assets/img/logo.png" alt="#" class="logo-img my-lg-3 mr-lg-5">
                    </router-link>
                </template>
            </lego-nav-bar>
        </div>
        <transition name="fade" mode="out-in">
            <Banner v-if="$router.history.current.path === '/'"></Banner>
            <InnerBanner v-else></InnerBanner>
        </transition>
        <div class="">
            <transition name="fade" mode="out-in">
                <router-view>

                </router-view>
            </transition>
        </div>

        <footer-section/>
    </div>
</template>

<script>
import LegoNavBar from 'lego-framework/src/components/NavBar';
import Banner from '@/views/components/Banner';
import getMenu from '../data/menu';
import FooterSection from '@/views/components/FooterSection.vue';
import InnerBanner from '../views/components/InnerBanner';

export default {
    components: {
        InnerBanner,
        LegoNavBar,
        FooterSection,
        Banner
    },
    data () {
        return {
            menu: getMenu(),
            value: ''
        };
    },
    metaInfo: {
        titleTemplate: '%s - Project Name',
        htmlAttrs: {
            lang: 'en',
            amp: true
        }
    },
    name: 'MainLayout'
};
</script>

<style>

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}

.bg-2 {
    font-family: poppins-light, serif;
}
</style>
