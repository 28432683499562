<template>
    <div class="page page-normal">
        <notifications/>
        <div class="admin-nav nav-container nav-bg pb-lg-0 p-3 p-lg-0">
            <lego-nav-bar @link-event="linkEvent" drop-down-animation="fade"
                          :items="menu"
                          has-separator>
                <template slot="logo">
                    <div class="fl-eq" @click="$router.push('/app/')">
                        <img alt="Reache" class="p-3" src="../assets/img/logo.png">
                    </div>
                </template>
            </lego-nav-bar>
            <!--            <transition enter-active-class="slideInLeft" leave-active-class="slideInRight" mode="in-out">-->
            <modal title="NOTIFICATIONS" class="c-modal no-close c-p-0 bs-4 notification-modal"
                   enter-animation="animated slideInRight"
                   exit-animation="animated slideOutRight" ref="successModal"
                   width="100p">
                <div class="">
                    <div class="d-flex py-3 c-modal-c">
                        <div class="notification-icon bg-primary">
                            <i class="fa fa-facebook-f"></i>
                        </div>
                        <p class="mb-0 fs--2">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum eos illum
                            itaque maiores unde.</p>
                    </div>
                    <div class="d-flex py-3 c-modal-c">
                        <div class="notification-icon bg-primary">
                            <i class="fa fa-facebook-f"></i>
                        </div>
                        <p class="mb-0 fs--2">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum eos illum
                            itaque maiores unde.</p>
                    </div>
                    <div class="d-flex py-3 c-modal-c">
                        <div class="notification-icon bg-primary">
                            <i class="fa fa-facebook-f"></i>
                        </div>
                        <p class="mb-0 fs--2">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum eos illum
                            itaque maiores unde.</p>
                    </div>
                    <div class="d-flex py-3 c-modal-c">
                        <div class="notification-icon bg-primary">
                            <i class="fa fa-facebook-f"></i>
                        </div>
                        <p class="mb-0 fs--2">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum eos illum
                            itaque maiores unde.</p>
                    </div>
                    <div class="d-flex py-3 c-modal-c">
                        <div class="notification-icon bg-primary">
                            <i class="fa fa-facebook-f"></i>
                        </div>
                        <p class="mb-0 fs--2">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum eos illum
                            itaque maiores unde.</p>
                    </div>

                </div>
            </modal>
            <!--            </transition>-->

        </div>
        <div class="page-content px-5 bg-2 admin-section">
            <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in">
                <router-view>

                </router-view>
            </transition>
        </div>

        <btn @click="$refs.chatModal.show()" class="chat-container primary text-white">
            <i class="fa fa-comments-o"></i>
            <span>Chats</span>
        </btn>

        <modal class="chatModal c-modal c-p-0 no-close no-heading center" title="Chats" ref="chatModal" width="80r" header-color="">
            <chat-box/>
        </modal>

        <div class="bgc-primary-900 p-3">
            Powered by Xeoscript Technologies
        </div>
    </div>
</template>

<script>
import LegoNavBar from 'lego-framework/src/components/NavBar';
import menu from '@/data/menu';
import { mapActions } from 'vuex';
import axios from 'secure-axios';
import urls from '@/data/urls';
import ChatBox from '../views/Admin/ChatBox';

export default {
    name: 'AdminLayout',
    components: {
        ChatBox,
        LegoNavBar
    },
    data () {
        return {
            menu: menu()
        };
    },
    metaInfo: {
        titleTemplate: '%s - Reache',
        htmlAttrs: {
            lang: 'en',
            amp: true
        }
    },
    methods: {
        ...mapActions(['signOut']),
        redirect () {
            this.$router.push({ path: '/' });
        },

        linkEvent (name) {
            if (name === 'logout') {
                localStorage.clear();
                this.signOut();
                axios.get(urls.auth.logout);
                this.$router.push('/check-user/');
            }

            if (name === 'notification') {
                this.$refs.successModal.show();
            }
        }
    }
};
</script>

<style lang="scss">
.admin-section {
    margin-top: 90px;
}

.chatModal .modal-body {
    padding: 0 !important;
}

</style>
