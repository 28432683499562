import store from '../store';

const getMenu = function () {
    const user = store.getters.currentUser;

    const menu = [
        {
            text: 'Home',
            link: '/'
        },
        {
            text: 'About',
            items: [
                {
                    text: 'About Us',
                    link: '/about/'
                },
                {
                    text: 'Support Available',
                    link: '/about/support/'
                },
                {
                    text: 'How To join',
                    link: '/about/joining/'
                },
                {
                    text: 'Getting To Reache',
                    link: '/about/directions/'
                },
                {
                    text: 'Policies',
                    link: '/about/policies/'
                },
                {
                    text: 'Publications',
                    link: '/about/presenting-our-work/'
                },
                {
                    text: 'Contact Us',
                    link: '/Contact/'
                }
            ]
        },
        {
            text: 'Resources for Members',
            items: [
                {
                    text: 'Resources',
                    link: '/links-to-resources/'
                },
                {
                    text: 'Study Skills',
                    link: '/links-to-resources/self-management-study-skills/'
                },
                {
                    text: 'Personal Wellbeing',
                    link: '/links-to-resources/personal-well-being/'
                },
                {
                    text: 'Portfolio And PDP',
                    link: '/links-to-resources/portfolio-cv-and-pdp/'
                },
                {
                    text: 'English (OET)',
                    link: '/links-to-resources/english-oet/'
                },
                {
                    text: 'Plab 1',
                    link: '/links-to-resources/plab-1/'
                },
                {
                    text: 'Plab 2',
                    link: '/links-to-resources/plab-2-resources/'
                },
                {
                    text: 'Drug of the Week',
                    link: '/links-to-resources/drug-of-the-week-resources/'
                },
                {
                    text: 'Work in the NHS',
                    link: '/links-to-resources/preparing-for-work-in-the-nhs/'
                },
                {
                    text: 'Clinical Communication',
                    link: '/links-to-resources/safe-and-effective-clinical-communication/'
                },
                {
                    text: 'GMC Resources',
                    link: '/links-to-resources/gmc-resources-for-doctors/'
                }
            ]

        },
        {
            text: 'Can you Help?',
            items: [
                {
                    text: 'Help',
                    link: '/help/'
                },
                {
                    text: 'Donations',
                    link: '/Donation/'
                },
                {
                    text: 'English Practice',
                    link: '/Englishpractice/'
                },
                {
                    text: 'Consultants',
                    link: '/Consultants/'
                },
                {
                    text: 'General Practitioners',
                    link: '/GeneralPractitioners/'
                },
                {
                    text: 'Junior Doctors',
                    link: '/JuniorDoctors/'
                },
                {
                    text: 'Medical Students',
                    link: '/MedicalStudents/'
                }
                // {
                //     text: 'Other Professionals',
                //     link: '/OtherProfessionals/'
                // }
            ]
        },
        // {
        //     class: 'nav-btn',
        //     text: 'Login',
        //     link: '/login/',
        //     type: 'button',
        //     btnAttrs: {
        //         design: 'basic-b',
        //         color: 'primary'
        //     }
        // },
        // {
        //     class: 'nav-btn',
        //     text: 'Apply',
        //     link: '/register/',
        //     type: 'button',
        //     btnAttrs: {
        //         design: 'basic-a',
        //         color: 'primary'
        //     }
        // }
        {
            text: 'Login',
            link: '/login/'
        },
        {
            class: 'nav-btn',
            text: 'Apply',
            link: '/register/'
        }
    ];

    const adminMenu = [
        {
            text: 'Dashboard',
            link: '/dashboard/'
        },
        {
            text: 'Policies',
            link: '/admin/policy-list/'
        },
        {
            text: 'My Profile',
            link: '/admin/my-profile/'
        },
        {
            type: 'separator'
        },
        {
            class: 'notification-btn',
            icon: 'fa fa-bell-o',
            text: '',
            event: 'notification'
        },
        {
            class: 'notification-btn mr-5 red',
            icon: 'fa fa-sign-out',
            text: 'Logout',
            link: '/logout/'
        }
    ];

    const memberMenu = [
        {
            text: 'Dashboard',
            link: '/dashboard/'
        },
        {
            text: 'Attendance',
            link: '/member/attendance/'
        },
        {
            text: 'Events',
            link: '/member/events/'
        },
        {
            text: 'My Profile',
            link: '/member/my-profile/'
        },
        {
            text: 'Policies',
            link: '/member/policies/'
        },
        {
            type: 'separator'
        },
        {
            text: user.name,
            icon: 'fa fa-user-circle-o',
            link: '#',
            dropdownPlacement: 'right',
            items: [
                {
                    text: 'Change Password',
                    link: '/reset-password/'
                },
                {
                    text: 'Logout',
                    link: '/logout/'
                }
            ]
        }
    ];

    const staffMenu = [
        {
            text: 'Dashboard',
            link: '/dashboard/'
        },
        {
            text: 'File Management',
            link: '/file-management/'
        },
        {
            text: 'Policies',
            link: '/staff/policies/'
        },
        {
            text: 'Teaching Links',
            link: '/staff/teaching-links/'
        },
        {
            text: 'Video',
            link: '/staff/video/'
        },
        {
            text: 'My Profile',
            link: '/staff/my-profile/'
        },
        {
            type: 'separator'
        },
        {
            text: user.name,
            icon: 'fa fa-user-circle-o',
            link: '#',
            dropdownPlacement: 'right',
            items: [
                {
                    text: 'Change Password',
                    link: '/reset-password/'
                },
                {
                    text: 'Logout',
                    link: '/logout/'
                }
            ]
        }
    ];

    // Do something similar for any number of roles
    if (user.role && user.role === 1) {
        return [
            ...adminMenu
        ];
    } else if (user.role && (user.role === 2 || user.role === 3)) {
        return [
            ...staffMenu
        ];
    } else if (user.branch) {
        return [
            ...memberMenu
        ];
    } return [
        ...menu
    ];
};
export default getMenu;
